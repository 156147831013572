<template>
  <div class="Shenghuo">
    <div class="banner">
      <div class="container">
      </div>
    </div>
    <div class="cont pd40">
      <div class="container">
        <div class="headTit">
          <h3><span>Life</span> Center</h3>
        </div>
        <div>
          <el-row :gutter="24">
            <el-col :span="16">
              <div class="list">
                <el-row :gutter="24">
                  <el-col :span="12"
                          v-for="(item,index) in list"
                          :key="index">
                    <router-link :to="'/live-in-china-detail/'+item.id"
                                 class="lis">
                      <div class="imgbox">
                        <img :src="item.thumb"
                             alt="">
                      </div>
                      <div class="text">
                        <div class="head">
                          {{item.title}}
                        </div>
                        <div class="cont">
                          {{item.description}}
                        </div>
                      </div>
                    </router-link>
                  </el-col>
                </el-row>
                <el-pagination v-if="showpg"
                               background
                               layout="prev, pager, next"
                               @next-click="onNextPage"
                               @prev-click="onPrevPage"
                               @current-change="onCurrentChange"
                               :page-size="pageSize"
                               :total="total">
                </el-pagination>
              </div>

            </el-col>
            <el-col :span="8">
              <div class="tit">
                Student Travel Center
              </div>
              <router-link class="lslis"
                           :to="'/news-detail/'+item.id"
                           v-for="(item,index) in lvyou"
                           :key="index">
                <el-row :gutter="24">
                  <el-col :span="8">
                    <div class="imgbox">
                      <img :src="item.thumb"
                           alt="">
                    </div>
                  </el-col>
                  <el-col :span="16">
                    <h3>{{item.title}}</h3>
                  </el-col>
                </el-row>
              </router-link>

              <div class="tit">
                Job Wanted
              </div>
              <router-link class="lslis"
                           :to="'/news-detail/'+item.id"
                           v-for="(item,index) in news"
                           :key="index">
                <el-row :gutter="24">
                  <el-col :span="8">
                    <div class="imgbox">
                      <img :src="item.thumb"
                           alt="">
                    </div>
                  </el-col>
                  <el-col :span="16">
                    <h3>{{item.title}}</h3>
                  </el-col>
                </el-row>
              </router-link>
            </el-col>
          </el-row>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import axiosApi from "../api/api.js";
import { ref } from "vue";

export default {
  data () {
    return {
      news: [],
      lvyou: [],
    }
  },
  setup () {
    const list = ref()
    const total = ref(2)
    const pageSize = ref(12)
    const showpg = ref(false)
    const onCurrentChange = (val) => {
      axiosApi(
        'news/4?page=' + val, {}, 'get'
      ).then((res) => {
        list.value = res.data.data
        total.value = res.data.total
        pageSize.value = res.data.per_page
      })
    }
    const onNextPage = (val) => {
      axiosApi(
        'news/4?page=' + val, {
      }, 'get'
      ).then((res) => {
        list.value = res.data.data
        total.value = res.data.total
        pageSize.value = res.data.per_page
      })
    }
    const onPrevPage = (val) => {
      axiosApi(
        'news/4?page=' + val, {
      }, 'get'
      ).then((res) => {
        list.value = res.data.data
        total.value = res.data.total
        pageSize.value = res.data.per_page
      })
    }

    return {
      onCurrentChange,
      onNextPage,
      onPrevPage,
      list,
      total,
      pageSize,
      showpg
    }
  },
  beforeMount () {
    axiosApi('news/3', {}, 'get').then(res => {
      this.lvyou = res.data.data
    })
    axiosApi('news/18', {}, 'get').then(res => {
      this.news = res.data.data
    })
    axiosApi('news/4', {}, 'get').then(res => {
      this.showpg = true
      this.list = res.data.data
      this.total = res.data.total
      this.pageSize = res.data.per_page
    })

  }
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/neibanner2.jpg) top center no-repeat;
  height: 400px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.tit {
  border-left: 8px solid #0c3879;
  padding-left: 10px;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  line-height: 1;
}
.list {
  .lis {
    display: block;
    margin-bottom: 20px;
    border: 1px solid #dedede;
    .imgbox {
      img {
        width: 100%;
        height: 230px;
      }
    }
    .text {
      margin-top: 10px;
      padding: 20px;
      .date {
        color: #0c3879;
        font-size: 14px;
      }
      .head {
        font-size: 17px;
        font-weight: bold;
        margin-bottom: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #333;
        transition: ease 0.5s;
      }
      .cont {
        font-size: 14px;
        line-height: 24px;
        height: 48px;
        color: #666;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        transition: ease 0.5s;
      }
    }
    .more {
      text-decoration: underline;
      transition: ease 0.5s;
    }
    &:hover {
      .head {
        color: #0c3879;
      }
      .more {
        color: #ff7e00;
      }
    }
  }
}
.lslis {
  display: block;
  margin-bottom: 20px;
  h3 {
    font-size: 14px;
  }
}
</style>
